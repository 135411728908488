import * as React from 'react';
import { CloseOutlined, CodeOutlined } from '@ant-design/icons';
import { getFeatureFlagValue } from '@citrite/feature-flags';
import { Api, UserRole } from '@citrite/sf-api';
import { getLanguage } from '@citrite/translate';
import type { PiletApi, PiletUserContext } from '@sharefiledev/sharefile-appshell';
import { addBlocks } from './blocks';
import {
	AsyncPaywallBanner,
	AsyncPaywallModal,
	AsyncSolutionInstallModal,
} from './components';
import { EmptyModal } from './components/EmptyModal';
import { UserEmailInfo } from './components/types';
import { addBlockDevelopment } from './development';
import { FeatureFlag } from './featureFlags';
import { setLogger } from './logger';
import { AsyncDevelopmentPage } from './pages';
import { PiralTestStrings } from './piralTestStrings';
import {
	EntitlementsContentParams,
	entitlementsPiletBanners,
	entitlementsPiletModals,
	InstallContentParams,
} from './sdk';

const knownSmokeTestCustomers = [
	'pilet-tests.sharefiletest.com',
	'pilet-tests.sharefilestaging.com',
	'pilet-tests.sharefile.com',
];

const paywallModalProps = {
	centered: true,
	width: 900,
	footer: null,
	closeIcon: <CloseOutlined style={{ zIndex: 10 }} />,
	styles: { content: { padding: 0, borderRadius: 12 } },
};

export function setup(piletApi: PiletApi) {
	setLogger(piletApi.sf.getLogger());
	piletApi.sf.registerSfApiConfigHandler(config => Api.configure(config as any));
	addBlocks(piletApi);
	const context: PiletUserContext = piletApi.sf.piletUserContext.get();
	const validUserId = context.user.Id !== undefined && context.user.Id !== null;
	const isFreeTrial = context.account.IsFreeTrial ?? true;
	const userRID = `sharefile:account-${context.account.Id}/user-${context.user.Id}`;
	const userEmailInfo: UserEmailInfo = {
		userEmail: context.user.Email!,
		userFirstName: context.user.FirstName!,
		userLastName: context.user.LastName!,
	};
	let isClient = context.user.Roles?.includes(UserRole.Client) ?? true;
	if (context.user.Roles?.includes(UserRole.Employee)) {
		isClient = false;
	}
	const locale = getLanguage();
	let isAdministrator = false;
	if (
		context.user.Roles?.includes(UserRole.AdminBilling) &&
		context.user.Roles?.includes(UserRole.AdminChangePlan)
	) {
		isAdministrator = true;
	}
	if (isAdministrator) {
		piletApi.registerModal<InstallContentParams>(
			entitlementsPiletModals.solutionInstallModal,
			({ completeInteraction, cancelInteraction, options }) => (
				<AsyncSolutionInstallModal
					completeInteraction={completeInteraction}
					cancelInteraction={cancelInteraction}
					locale={locale}
					{...options!}
				/>
			),
			{
				modalProps: {
					width: 640,
					footer: null,
					styles: { content: { padding: 0, borderRadius: 12 } },
				},
			}
		);
	}
	if (isClient || !validUserId) {
		piletApi.registerModal(
			entitlementsPiletModals.entitlementsModal,
			({ ...cancelInteraction }) => <EmptyModal {...cancelInteraction} />,
			{
				modalProps: {
					width: 0,
					footer: null,
				},
			}
		);
		piletApi.registerExtension(entitlementsPiletBanners.entitlementsBanner, ({}) => (
			<></>
		));
	} else {
		piletApi.registerModal<EntitlementsContentParams>(
			entitlementsPiletModals.entitlementsModal,
			({ completeInteraction, cancelInteraction, options }) => (
				<AsyncPaywallModal
					piletApi={piletApi}
					entitlementsContent={options!}
					isAdmin={isAdministrator}
					isFreeTrial={isFreeTrial}
					locale={locale}
					userRID={userRID}
					userEmailInfo={userEmailInfo}
					completeInteraction={completeInteraction}
					cancelInteraction={cancelInteraction}
				/>
			),
			{
				modalProps: paywallModalProps,
			}
		);
		piletApi.registerExtension<EntitlementsContentParams>(
			entitlementsPiletBanners.entitlementsBanner,
			({ params }) => (
				<AsyncPaywallBanner
					piletApi={piletApi}
					entitlementsContent={params}
					isFreeTrial={isFreeTrial}
					isAdmin={isAdministrator}
					locale={locale}
					userRID={userRID}
					userEmailInfo={userEmailInfo}
				/>
			)
		);
	}
	if (isTestPageEnabled()) {
		// eslint-disable-next-line no-console
		console.log('Adding entitlements test page');
		addBlockDevelopment(piletApi);
		piletApi.registerPage('/entitlements', () => (
			<AsyncDevelopmentPage
				piletApi={piletApi}
				isAdmin={isAdministrator}
				isFreeTrial={isFreeTrial}
				userRID={userRID}
				userEmailInfo={userEmailInfo}
			/>
		));
		piletApi.sf.registerLeftNavComponent({
			href: '/entitlements',
			title: () => 'Entitlements Page',
			weight: 200,
			icon: () => <CodeOutlined style={{ fontSize: '24px' }} />,
		});
		piletApi.registerExtension<EntitlementsContentParams>(
			PiralTestStrings.paidUserModal,
			({ params }) => (
				<AsyncPaywallBanner
					piletApi={piletApi}
					entitlementsContent={params}
					isFreeTrial={false}
					isAdmin={false}
					locale={locale}
					userRID={userRID}
					userEmailInfo={userEmailInfo}
				/>
			)
		);
		piletApi.registerExtension<EntitlementsContentParams>(
			PiralTestStrings.paidAdminBanner,
			({ params }) => (
				<AsyncPaywallBanner
					piletApi={piletApi}
					entitlementsContent={params}
					isFreeTrial={false}
					isAdmin
					userRID={userRID}
					userEmailInfo={userEmailInfo}
					locale={locale}
				/>
			)
		);
		piletApi.registerExtension<EntitlementsContentParams>(
			PiralTestStrings.trialUserBanner,
			({ params }) => (
				<AsyncPaywallBanner
					piletApi={piletApi}
					entitlementsContent={params}
					isFreeTrial
					isAdmin={false}
					userRID={userRID}
					userEmailInfo={userEmailInfo}
					locale={locale}
				/>
			)
		);
		piletApi.registerExtension<EntitlementsContentParams>(
			PiralTestStrings.trialAdminBanner,
			({ params }) => (
				<AsyncPaywallBanner
					piletApi={piletApi}
					entitlementsContent={params}
					isFreeTrial
					isAdmin
					userRID={userRID}
					userEmailInfo={userEmailInfo}
					locale={locale}
				/>
			)
		);
		piletApi.registerModal<EntitlementsContentParams>(
			PiralTestStrings.paidUserModal,
			({ completeInteraction, cancelInteraction, options }) => (
				<AsyncPaywallModal
					piletApi={piletApi}
					entitlementsContent={options!}
					isAdmin={false}
					isFreeTrial={false}
					locale={locale}
					userRID={userRID}
					userEmailInfo={userEmailInfo}
					completeInteraction={completeInteraction}
					cancelInteraction={cancelInteraction}
				/>
			),
			{
				modalProps: paywallModalProps,
			}
		);
		piletApi.registerModal<EntitlementsContentParams>(
			PiralTestStrings.paidAdminModal,
			({ completeInteraction, cancelInteraction, options }) => (
				<AsyncPaywallModal
					piletApi={piletApi}
					entitlementsContent={options!}
					isAdmin
					isFreeTrial={false}
					locale={locale}
					userRID={userRID}
					userEmailInfo={userEmailInfo}
					completeInteraction={completeInteraction}
					cancelInteraction={cancelInteraction}
				/>
			),
			{
				modalProps: paywallModalProps,
			}
		);
		piletApi.registerModal<EntitlementsContentParams>(
			PiralTestStrings.trialUserModal,
			({ completeInteraction, cancelInteraction, options }) => (
				<AsyncPaywallModal
					piletApi={piletApi}
					entitlementsContent={options!}
					isAdmin={false}
					isFreeTrial
					locale={locale}
					userRID={userRID}
					userEmailInfo={userEmailInfo}
					completeInteraction={completeInteraction}
					cancelInteraction={cancelInteraction}
				/>
			),
			{
				modalProps: paywallModalProps,
			}
		);
		piletApi.registerModal<EntitlementsContentParams>(
			PiralTestStrings.trialAdminModal,
			({ completeInteraction, cancelInteraction, options }) => (
				<AsyncPaywallModal
					piletApi={piletApi}
					entitlementsContent={options!}
					isAdmin
					isFreeTrial
					locale={locale}
					userRID={userRID}
					userEmailInfo={userEmailInfo}
					completeInteraction={completeInteraction}
					cancelInteraction={cancelInteraction}
				/>
			),
			{
				modalProps: paywallModalProps,
			}
		);
	}
}

function isTestPageEnabled(): boolean {
	if (
		process.env.NODE_ENV === 'development' ||
		knownSmokeTestCustomers.includes(location.hostname)
	) {
		return true;
	}
	try {
		return getFeatureFlagValue(FeatureFlag.EnablePLGTestPage) ?? false;
	} catch (Error) {
		console.error(Error);
	}
	return false;
}
