import React from 'react';
import { Spin } from 'antd';
import { LocalizationLoader } from '../translate';
import { DevelopmentPageProps } from './Development/DevelopmentPage';

const LazyDevelopmentPage = React.lazy(() => import('./Development/DevelopmentPage'));
export const AsyncDevelopmentPage: React.FC<DevelopmentPageProps> = props => (
	<React.Suspense fallback={<Spin />}>
		<LazyDevelopmentPage {...props} />
	</React.Suspense>
);

const LazyErrorPage = React.lazy(() => import('../pages/ErrorPage'));
export const AsyncErrorPage: React.FC<{}> = ({}) => (
	<React.Suspense fallback={<Spin />}>
		<LocalizationLoader loadingElement={<Spin />}>
			{() => <LazyErrorPage />}
		</LocalizationLoader>
	</React.Suspense>
);
