/**
 * These are all runtime dependencies. Any breaking changes
 * will need a migration path first!
 */

import type {} from '@sharefiledev/sharefile-appshell'; // force tsc to resolve this package

const prefix = 'urn';
const namespace = 'sfmodal';
const owner = 'entitlements';

export const entitlementsPiletModals = {
	entitlementsModal: `${prefix}:${namespace}:${owner}:paywall` as const,
	solutionInstallModal: `${prefix}:${namespace}:${owner}:install` as const,
};

const extensionNamespace = 'sfextslot';

export const entitlementsPiletBanners = {
	entitlementsBanner: `${prefix}:${extensionNamespace}:${owner}:limitbanner` as const,
};

export interface EntitlementsContentParams {
	entitlementDefRID: string;
	entitlementRID: string;
	entitled: boolean;
	evaluationResult: {
		isPaywallEnabled: boolean;
		showLimitBanner: boolean;
		limit: string;
		current: string;
		requested: string;
		remaining: string;
	};
	httpStatusCodeResponseToCaller: number;
	storageInfo?: {
		baseStorageMB: number;
		storagePackStorageMB: number;
		perUserStorageMB: number;
		totalStorageMB: number;
		percentageUsed: number;
	};
}

export interface SolutionInstallContentParams {
	solutionName: string;
	numberOfDays: number;
}

declare module '@sharefiledev/sharefile-appshell' {
	interface PiralCustomOverlayOptionsMap {
		[entitlementsPiletModals.entitlementsModal]: EntitlementsContentParams;
		[entitlementsPiletModals.solutionInstallModal]: SolutionInstallContentParams;
	}

	interface PiralCustomOverlayDataMap {
		[entitlementsPiletModals.entitlementsModal]: undefined;
	}

	interface PiralCustomExtensionSlotMap {
		[entitlementsPiletBanners.entitlementsBanner]: EntitlementsContentParams;
	}
}
